import {
  Alert,
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loading from "../components/common/Loading";
import MenuItem from "@mui/material/MenuItem";
import timeApi from "../api/timeApi";
import { DateTime, Interval } from "luxon";
import CurrencyFormat from "react-currency-format";
import UserMenu from "../components/common/UserMenu";
import { config } from "../config";

const serviceFee = 2.25;

const initDate = "2022-10-30";
const rates = [
  {
    label: "Regular",
    rate: "hourly",
    value: "hours",
  },
  {
    label: "Overtime",
    rate: "overtime_hourly",
    value: "overtime_hours",
  },
  {
    label: "Holiday",
    rate: "holiday_hourly",
    value: "holiday_hours",
  },
];
const defaultTime = {
  _id: null,
  user: null,
  hours: 0,
  overtime_hours: 0,
  holiday_hours: 0,
  date: null,
  stripe_payment_id: null,
};

const Time = () => {
  const user = useSelector((state) => state.user.value);
  const [title, setTitle] = useState("Load Manager > Time");
  const [loading, setLoading] = useState(true);
  const [times, setTimes] = useState(undefined);
  const [periods, setPeriods] = useState([]);
  const [selectedWorkWeek, setSelectedWorkWeek] = useState("");
  const [selectedTime, setSelectedTime] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: "success",
    message: "",
  });

  useEffect(() => {
    if (!times) init();
  }, [selectedWorkWeek, times]);

  const getTimes = async (params) => {
    try {
      const res = await timeApi.getAll(params);
      setTimes(res);
      return res;
    } catch (err) {
      console.error(err);
    }
  };

  const isAdmin = () => {
    return user.role === "admin";
  };

  const init = async () => {
    const interval = Interval.fromDateTimes(
      DateTime.fromSQL(initDate),
      DateTime.now()
    );

    const weeks = interval.splitBy({ weeks: 1 }).map((d) => d.start);
    const latestWeek = weeks[weeks.length - 1].toSQLDate();
    setPeriods(weeks.map((date) => date.toSQLDate()));
    if (!selectedWorkWeek) {
      if (isAdmin()) {
        const times = await getTimes({ date: latestWeek });
      } else {
        const times = await getTimes();
        weeks.map((date) => {
          const weekDate = date.toSQLDate();
          const timeExists = times.find((time) => time.date === weekDate);
          if (!timeExists) {
            const newTime = { ...defaultTime };
            newTime.user = user._id;
            newTime.date = weekDate;
            const timesCopy = [...times];
            timesCopy.push(newTime);
            setTimes(timesCopy);
            setSelectedTime(newTime);
          }
        });
      }

      setSelectedWorkWeek(latestWeek);
      setLoading(false);
    }
  };

  const changeSelectedTime = async (newDate) => {
    if (isAdmin()) await getTimes({ date: newDate });
    const selected = times.find((time) => time.date === newDate);
    setSelectedTime(selected);
  };

  const getTotal = () => {
    return rates.reduce((total, rate) => {
      total += selectedTime[rate.value] * user.rate[rate.rate];
      return total;
    }, 0);
  };

  const submit = async () => {
    const selectedTimeCopy = { ...selectedTime };
    selectedTimeCopy.total = getTotal();
    setSnackbar({
      open: true,
      severity: "info",
      message: "Saving!",
    });
    try {
      await timeApi.create(selectedTimeCopy);
      setSnackbar({
        open: true,
        severity: "success",
        message: "Saved!",
      });
      await getTimes({ date: selectedWorkWeek });
      closeSnackbar();
    } catch (err) {
      setSnackbar({
        open: true,
        severity: "error",
        message: err.data.error,
      });
    }
  };

  const closeSnackbar = () => {
    setTimeout(() => {
      setSnackbar({
        open: false,
        severity: "success",
        message: "",
      });
    }, 2000);
  };

  const approve = async (timeId) => {
    setSnackbar({
      open: true,
      severity: "info",
      message: "Approving!",
    });
    try {
      await timeApi.approve(timeId);
      setSnackbar({
        open: true,
        severity: "success",
        message: "Approved!",
      });
      await getTimes({ date: selectedWorkWeek });
      closeSnackbar();
    } catch (err) {
      setSnackbar({
        open: true,
        severity: "error",
        message: err.data.error,
      });
    }
  };

  const currencyFormat = (amount) => {
    return (
      <CurrencyFormat
        value={amount}
        displayType={"text"}
        thousandSeparator={true}
        decimalScale={2}
        fixedDecimalScale={true}
        prefix={"$"}
      />
    );
  };

  const workWeekSelect = () => {
    return (
      <Grid item xs={12}>
        <FormControl sx={{ m: 1, minWidth: 120, marginBottom: 3 }} size="small">
          <InputLabel id="demo-select-small">Work Week</InputLabel>
          <Select
            value={selectedWorkWeek}
            label={"Work Week"}
            onChange={(event) => {
              setSelectedWorkWeek(event.target.value);
              changeSelectedTime(event.target.value);
            }}
          >
            {periods.map((date) => {
              return (
                <MenuItem key={date} value={date}>
                  {date}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const showUser = () => {
    if (
      user.rate &&
      user.rate.hourly &&
      user.rate.holiday_hourly &&
      user.rate.overtime_hourly
    ) {
      return (
        <Grid container>
          <Grid item xs={12} md={6} sx={{ paddingX: 2 }}>
            <Grid container>
              <Grid item xs={12} sx={{ paddingY: 2 }}>
                <Typography variant="h5">Submit Payroll:</Typography>
                <Divider sx={{ marginTop: 2 }} />
              </Grid>
              {workWeekSelect()}
              <Grid item xs={3}>
                Type
              </Grid>
              <Grid item xs={3}>
                Time
              </Grid>
              <Grid item xs={3}>
                Rate
              </Grid>
              <Grid item xs={3}>
                Amount
              </Grid>
              <Grid item xs={12} sx={{ paddingY: 2 }}>
                <Divider />
              </Grid>
              {rates.map((rate, i) => {
                return (
                  <Grid item key={i} xs={12}>
                    <Grid container>
                      <Grid item xs={3} sx={{ paddingTop: 2 }}>
                        {rate.label}
                      </Grid>
                      <Grid item xs={3} sx={{ paddingY: 1 }}>
                        {selectedTime && !selectedTime.stripe_payment_id ? (
                          <TextField
                            sx={{ maxWidth: "100px" }}
                            value={selectedTime ? selectedTime[rate.value] : 0}
                            variant="outlined"
                            label="Hours"
                            type="number"
                            size="small"
                            onChange={(event) => {
                              const selectedTimeCopy = { ...selectedTime };
                              selectedTimeCopy[rate.value] = event.target.value;
                              setSelectedTime(selectedTimeCopy);
                            }}
                          ></TextField>
                        ) : (
                          <Box sx={{ paddingTop: 1 }}>
                            {selectedTime && selectedTime[rate.value]}
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={3} sx={{ paddingTop: 2 }}>
                        {currencyFormat(user.rate[rate.rate])}
                      </Grid>
                      <Grid item xs={3} sx={{ paddingTop: 2 }}>
                        {currencyFormat(
                          selectedTime
                            ? selectedTime[rate.value] * user.rate[rate.rate]
                            : 0
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
              <Grid item xs={12} sx={{ paddingY: 2 }}>
                <Divider />
              </Grid>
              <Grid item xs={9}>
                Total (USD)
              </Grid>
              <Grid item xs={3}>
                {currencyFormat(selectedTime ? getTotal() : 0)}
              </Grid>
              <Grid item xs={9}></Grid>
              <Grid item xs={3} sx={{ paddingTop: 3 }}>
                {selectedTime && !selectedTime.stripe_payment_id && (
                  <Button color="primary" variant="contained" onClick={submit}>
                    Submit Time
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} sx={{ paddingX: 2 }}>
            <Grid
              container
              sx={{
                overflowY: "scroll",
                maxHeight: "calc(100vh - 100px)",
                borderBottom: "1px solid #DDD",
              }}
            >
              <Grid item xs={12} sx={{ paddingY: 2 }}>
                <Typography variant="h5">Payroll History:</Typography>
                <Divider sx={{ marginTop: 2 }} />
              </Grid>
              {times.length > 0 ? (
                <>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      paddingX: 2,
                      paddingBottom: 2,
                      fontWeight: "bold",
                    }}
                  >
                    Week
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{ paddingBottom: 2, fontWeight: "bold" }}
                  >
                    Total (USD)
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{ paddingBottom: 2, fontWeight: "bold" }}
                  >
                    Status
                  </Grid>
                  {times
                    .filter((time) => time.total !== undefined)
                    .map((time) => {
                      return (
                        <Grid item xs={12} key={time.date}>
                          <Grid
                            container
                            sx={{
                              paddingX: 2,
                              paddingY: 1,
                              borderBottom: "1px solid #DDD",
                            }}
                          >
                            <Grid item xs={4}>
                              {time.date}
                            </Grid>
                            <Grid item xs={4}>
                              {currencyFormat(time.total)}
                            </Grid>
                            <Grid item xs={4}>
                              {time.stripe_payment_id ? (
                                <Chip
                                  sx={{ height: "22px" }}
                                  label="Approved"
                                  color="success"
                                  variant="outlined"
                                />
                              ) : (
                                <Chip
                                  sx={{ height: "22px" }}
                                  label="Pending"
                                  color="warning"
                                  variant="outlined"
                                />
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                </>
              ) : (
                <Alert severity="info">No Payroll History</Alert>
              )}
            </Grid>
          </Grid>
        </Grid>
      );
    } else {
      return <></>;
    }
  };

  const showAdmin = () => {
    const columns = ["Staff", ...rates, "Total (USD)", "Approve"];
    return (
      <Grid container>
        {workWeekSelect()}
        {times.length === 0 && (
          <Alert variant="outlined" color="warning" sx={{ width: "100%" }}>
            No Times Submitted Yet!
          </Alert>
        )}
        {times.length > 0 &&
          columns.map((col) => {
            return (
              <Grid
                item
                key={col.label}
                xs={12 / columns.length}
                sx={{
                  paddingBottom: 2,
                  fontWeight: "bold",
                }}
              >
                {col.label ? col.label + " (Rate)" : col}
              </Grid>
            );
          })}
        {times.length > 0 &&
          times
            .filter((time) => time.user)
            .map((time) => {
              return (
                <Grid item xs={12} key={time._id}>
                  <Grid
                    container
                    sx={{
                      paddingY: 1,
                      borderBottom: "1px solid #DDD",
                    }}
                  >
                    <Grid item xs={2}>
                      {time.user.firstname + " " + time.user.lastname}
                    </Grid>
                    {rates.map((rate) => {
                      return (
                        <Grid item key={"rate_" + time._id + rate.value} xs={2}>
                          {time[rate.value]}
                          <Box
                            sx={{
                              color: "#888",
                              display: "inline-block",
                              marginLeft: 1,
                            }}
                          >
                            ({currencyFormat(time.user.rate[rate.rate])})
                          </Box>
                        </Grid>
                      );
                    })}
                    <Grid item xs={2}>
                      {currencyFormat(time.total)}
                    </Grid>
                    <Grid item xs={2}>
                      {!time.stripe_payment_id ? (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => approve(time._id)}
                        >
                          Approve
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          color="success"
                          size="small"
                          target="_blank"
                          href={
                            config.stripePaymentLink +
                            "/" +
                            time.stripe_payment_id
                          }
                        >
                          View
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
      </Grid>
    );
  };

  return (
    <>
      {loading ? (
        <Loading fullHeight />
      ) : (
        <Box>
          <Snackbar
            open={snackbar.open}
            onClose={closeSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={closeSnackbar}
              severity={snackbar.severity}
              sx={{ width: "100%" }}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
          <Box
            sx={{
              height: "56px",
              padding: "8px",
              borderBottom: "1px solid #AAA",
              textAlign: "center",
              lineHeight: "42px",
            }}
          >
            <Box
              sx={{
                float: "right",
                width: "auto",
              }}
            >
              <UserMenu />
            </Box>
            <Box
              id="title"
              sx={{
                float: "left",
                fontSize: "1.2rem",
                fontWeight: "700",
                paddingLeft: 2,
                color: "primary.main",
              }}
            >
              {title}
            </Box>
          </Box>
          <Box sx={{ margin: 2 }}>{isAdmin() ? showAdmin() : showUser()}</Box>
        </Box>
      )}
    </>
  );
};

export default Time;
