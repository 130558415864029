import React, { useEffect, useState, useContext } from "react";
import { SocketContext } from "./socket.context";
import Moment from "moment";

const LoadContext = React.createContext();

const LoadProvider = (props) => {
  const { socket } = useContext(SocketContext);

  const [items, setItems] = useState([]);
  const [reloadCompanies, setReloadCompanies] = useState(1);
  const [editItem, setEditItem] = useState();

  useEffect(() => {
    if (socket) {
      socket.on("loadCreate", (load) => {
        addLoad(load);
      });

      socket.on("loadUpdate", (load) => {
        updateLoad(load);
      });

      socket.on("loadDelete", (load) => {
        deleteLoad(load);
      });

      socket.on("updatePosition", (loads) => {
        updateLoad(loads);
      });

      return () => {
        socket.off("loadCreate");
        socket.off("loadUpdate");
        socket.off("loadDelete");
        socket.off("updatePosition");
      };
    }
  }, [items, socket]);

  const updateLoad = (loadUpdate) => {
    if (loadUpdate.reloadCompanies) {
      delete loadUpdate.reloadCompanies;
      setReloadCompanies(reloadCompanies + 1);
    }
    const loadsCopy = items.map((load) => {
      if (Array.isArray(loadUpdate)) {
        const foundLoad = loadUpdate.find((loadUp) => loadUp._id === load._id);
        if (foundLoad) return foundLoad;
        else return load;
      } else if (load._id === loadUpdate._id) return loadUpdate;
      else return load;
    });
    setItems(loadsCopy);
  };

  const addLoad = (loadAdd) => {
    const loadsCopy = [...items];
    if (loadAdd.reloadCompanies) {
      delete loadAdd.reloadCompanies;
      setReloadCompanies(reloadCompanies + 1);
    }
    const alreadyExists = loadsCopy.find((load) => load._id === loadAdd._id);
    if (!alreadyExists) {
      loadsCopy.push(loadAdd);
      setItems(loadsCopy);
    }
  };

  const deleteLoad = (loadDelete) => {
    const loadsCopy = items
      .filter((load) => loadDelete.load._id !== load._id)
      .map((load) => {
        const foundLoad = loadDelete.loads.find(
          (loadUp) => loadUp._id === load._id
        );
        if (foundLoad) return foundLoad;
        else return load;
      });
    setItems(loadsCopy);
  };

  const sort = (items) => {
    return items.sort((a, b) => {
      if (!a.qe_id) return -1;
      else if (!b.qe_id) return 1;
      else if (!a.alert && !b.alert)
        return parseInt(a.qe_id) - parseInt(b.qe_id);
      else if (!a.alert && b.alert) return 1;
      else if (a.alert && !b.alert) return -1;
      else {
        return Moment(a.alert).valueOf() - Moment(b.alert).valueOf();
      }
    });
  };

  const values = {
    items: items,
    setItems: setItems,
    updateLoad,
    addLoad,
    deleteLoad,
    reloadCompanies,
    editItem: editItem,
    setEditItem: setEditItem,
    sort: sort,
  };
  return (
    <LoadContext.Provider value={values}>{props.children}</LoadContext.Provider>
  );
};

export { LoadContext, LoadProvider };
