import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { store } from './redux/store'
import { Provider } from 'react-redux';
import { config } from "./config";
import { PublicClientApplication } from "@azure/msal-browser";

export const msalInstance = new PublicClientApplication(config.sso.msalConfig);

msalInstance.initialize().then(() => {
  msalInstance.handleRedirectPromise().then(async (res) => {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length === 0) {
      await msalInstance.loginRedirect({scopes: config.sso.scopes})
    } else {
      const root = ReactDOM.createRoot(document.getElementById('root'));
      root.render(
        <Provider store={store}>
          <App />
        </Provider>
      );
    }
  }).catch(err => console.error(err));
});
