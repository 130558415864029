import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ViewKanbanOutlinedIcon from "@mui/icons-material/ViewKanbanOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import TaskIcon from "@mui/icons-material/Task";
import { msalInstance } from "../..";

const UserMenu = (props) => {
  const boards = useSelector((state) => state.board.value);

  const user = useSelector((state) => state.user.value);
  const [anchorEl, setAnchorEl] = useState(null);

  const logout = () => {
    const accounts = msalInstance.getAllAccounts();
    msalInstance.logoutRedirect({account: accounts[0]})
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {boards.filter((board) => !board.type || board.type === 'load').map((tenant, index) => (
          <MenuItem
            key={"tenant" + index}
            component={Link}
            reloadDocument
            to={"/boards/" + tenant.id}
          >
            <ListItemIcon><ViewKanbanOutlinedIcon fontSize="small" /></ListItemIcon>
            <ListItemText>{tenant.title}</ListItemText>
          </MenuItem>
        ))}
        <Divider />
        {boards.filter((board) => board.type === 'task').map((tenant, index) => (
          <MenuItem
            key={"tenant" + index}
            component={Link}
            reloadDocument
            to={"/boards/" + tenant.id}
          >
            <ListItemIcon><TaskIcon fontSize="small" /></ListItemIcon>
            <ListItemText>{tenant.title}</ListItemText>
          </MenuItem>
        ))}
        <Divider />
        {(user.rate || user.role === "admin") && (
          <MenuItem component={Link} to="/time">
            <ListItemIcon>
              <AccessTimeOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Time</ListItemText>
          </MenuItem>
        )}
        {user.role === "admin" && (
          <MenuItem component={Link} to="/users">
            <ListItemIcon>
              <GroupOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Users</ListItemText>
          </MenuItem>
        )}
        <MenuItem component={Link} to="/account">
          <ListItemIcon>
            <AccountCircleOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Account</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <ExitToAppOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
