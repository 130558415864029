import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import authUtils from "../../utils/authUtils";
import Loading from "../common/Loading";
import { setUser } from "../../redux/features/userSlice";
import boardApi from "../../api/boardApi";
import { setBoards } from "../../redux/features/boardSlice";

const AppLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { boardId } = useParams();
  const boards = useSelector((state) => state.board.value);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = async () => {
    const user = await authUtils.isAuthenticated();
   
    dispatch(setUser(user));

    await getBoards();

    setLoading(false);
  };

  useEffect(() => {
    if (!loading && boards.length === 0) {
      alert('No Load Boards Found!');
    } else if (boards.length > 0 && boardId === undefined) {
      navigate(`/boards/${boards[0].id}`);
    }
  }, [boards, loading])

  const getBoards = async () => {
    try {
      const res = await boardApi.getAll();
      dispatch(setBoards(res));
    } catch (err) {
      console.error(err);
      alert(err.data);
    }
  };

  return loading ? (
    <Loading fullHeight />
  ) : (
    <Box
      sx={{
        display: "flex",
      }}
    >
      {/* <Sidebar /> */}
      <Box
        sx={{
          flexGrow: 1,
          width: "max-content",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default AppLayout;
