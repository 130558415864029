import TaskIcon from "@mui/icons-material/Task";

const env = process.env;
export const config = {
  api: env.REACT_APP_API_URL,
  apiRoutes: "/api/v1/",
  stripePaymentLink: "https://dashboard.stripe.com/payments",
  homeBoard: "63575eda624c8e7e871f920b",
  sso: {
    msalConfig: {
      auth: {
        clientId: env.REACT_APP_AZURE_CLIENT_ID,
        authority: 'https://login.microsoftonline.com/' + env.REACT_APP_AZURE_TENANT_ID,
        redirectUri: env.REACT_APP_AZURE_REDIRECT_URI, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: env.REACT_APP_AZURE_REDIRECT_URI, // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
      },
      cache: {
        cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      },
    },
    scopes: ["User.Read"]
  },
  novu: {
    subscriberId: "loadmanager-client-prod",
    applicationIdentifier: "NC2W02U31ezX",
  },
  boards: [
    {
      name: "Tasks",
      id: null,
      board_id: "64767033cf4e38cd372df716",
      icon: <TaskIcon fontSize="small" />,
    },
  ],
};
