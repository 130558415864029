import {
  Backdrop,
  Fade,
  IconButton,
  Modal,
  Box,
  TextField,
  Input,
  FormControl,
  Autocomplete,
  Button,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  InputAdornment,
  Tab,
  Tabs,
  List,
  ListItem,
  Card,
  Alert,
  Snackbar,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Toolbar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

import Moment from "moment";
import loadApi from "../../api/loadApi";
import "../../css/custom-editor.css";
import companyApi from "../../api/companyApi";
import Loading from "./Loading";
import { useSelector } from "react-redux";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DateTime } from "luxon";

const companyOrder = ["customer", "shipper", "carrier", "receiver"];

class Load {
  _id = undefined;
  title = "";
  section = "";
  alert = "";
  notes = "";
  customer = null;
  shipper = null;
  carrier = null;
  receiver = null;
  pickup_address = "";
  delivery_address = "";
  pro_number = "";
  tracking_url = "";
  tracking_number = "";
}

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const LoadModal = (props) => {
  const boardId = props.boardId;
  const user = useSelector((state) => state.user.value);
  const [load, setLoad] = useState(new Load());
  const [section, setSection] = useState();
  const [alert, setAlert] = useState({ number: "", unit: "" });
  const [companies, setCompanies] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [newCompanyTypes, setNewCompanyTypes] = useState({});
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: "success",
    message: "",
  });

  useEffect(() => {
    if (props.load !== undefined && load._id === undefined) {
      setSection(props.sections.find((section) => section.id === props.load.section));
      setLoad(Object.assign(new Load(), props.load));
      lockLoad();
      if (companies.length === 0) getCompanies();
    } else setLoad(new Load());
  }, [props.load]);

  const lockLoad = async () => {
    setLoading(true);
    try {
      await loadApi.lock(boardId, props.load.id, {
        user_locked: {
          user_id: user._id,
          name: user.firstname,
          timestamp: new Date(),
        },
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      let msg = "Failed to Lock!";
      if (err.data.error) msg = err.data.error;
      props.onClose(msg);
    }
  };

  const unlockLoad = async () => {
    await loadApi.lock(boardId, props.load.id, {
      user_locked: null,
    });
  };

  const closeSnackbar = () => {
    setSnackbar({
      open: false,
      severity: "success",
      message: "",
    });
  };

  const getCompanies = async () => {
    try {
      const res = await companyApi.getAll();
      setCompanies(res);
    } catch (err) {
      setSnackbar({
        open: true,
        severity: "error",
        message: "Failed to get companies",
      });
    }
  };

  const onClose = async (saved) => {
    unlockLoad();
    if (Object.values(newCompanyTypes).find((type) => type === true)) {
      getCompanies();
    }
    setNewCompanyTypes({});
    props.onClose(null, saved);
  };

  const canDelete = () => {
    return !user.isCustomer || section.customer_access === 'delete';
  }

  const isReadOnly = () => {
    return user.isCustomer && section.customer_access === 'read';
  }

  const deleteLoad = async () => {
    if (window.confirm("Are you sure you want to delete load?")) {
      try {
        await loadApi.delete(boardId, load.id);
        props.onDelete(load);
      } catch (err) {
        setSnackbar({
          open: true,
          severity: "error",
          message: "Failed to delete load",
        });
      }
    }
  };

  const updateTitle = async (event) => {
    const loadCopy = { ...load };
    loadCopy.qe_id = event.target.value;
    setLoad(loadCopy);
  };

  const updateProNumber = (event) => {
    const loadCopy = { ...load };
    loadCopy.pro_number = event.target.value;
    setLoad(loadCopy);
  };

  const updateReferenceId = (event) => {
    const loadCopy = { ...load };
    loadCopy.reference_id = event.target.value;
    setLoad(loadCopy);
  };

  const updatePrice = (event) => {
    const loadCopy = { ...load };
    loadCopy.price = event.target.value;
    setLoad(loadCopy);
  };

  const updateCompany = async (id, type) => {
    const loadCopy = { ...load };
    loadCopy[type] = id;
    setLoad(loadCopy);
  };

  const updateTrackingUrl = async (event) => {
    const loadCopy = { ...load };
    loadCopy.tracking_url = event.target.value;
    setLoad(loadCopy);
  };

  const updateShippingAddress = (event) => {
    const loadCopy = { ...load };
    loadCopy.pickup_address = event.target.value;
    setLoad(loadCopy);
  };

  const updateDestinationAddress = (event) => {
    const loadCopy = { ...load };
    loadCopy.delivery_address = event.target.value;
    setLoad(loadCopy);
  };

  const updateAlert = (alertCopy) => {
    setAlert(alertCopy);
    let alertTime = Moment();
    alertTime = alertTime.add(alertCopy.number, alertCopy.unit);
    load.alert = alertTime;
    setLoad(load);
  };

  const updateNotes = (event) => {
    const loadCopy = { ...load };
    loadCopy.notes = event.target.value;
    setLoad(loadCopy);
  };

  const updateStatus = (event) => {
    const newStatus = event.target.value;
    const loadCopy = { ...load };
    loadCopy.old_section = load.section;
    loadCopy.section = newStatus;
    setLoad(loadCopy);
  };

  const save = async () => {
    try {
      await loadApi.update(boardId, load.id, load);
      // props.onUpdate(load);
      onClose(true);
    } catch (err) {
      let msg = "Failed to Save!";
      if (err.data.error) msg = err.data.error;
      setSnackbar({
        open: true,
        severity: "error",
        message: msg,
      });
    }
  };

  const typeUpper = (type) => {
    return type.charAt(0).toUpperCase() + type.slice(1);
  };

  const renderLocations = (type, title) => {
    return (
      <TableContainer component={Paper} my={2}>
        <Toolbar>
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {title} <Button sx={{marginLeft: 1}} variant="outlined" size="small" onClick={() => {
              const loadCopy = { ...load };
              loadCopy.locations.push({
                type: type,
                address: {},
                contact: {}
              });
              setLoad(loadCopy);
            }}>Add</Button>
          </Typography>
        </Toolbar>
        <Table sx={{ minWidth: 650 }} aria-label="shipping table">
          <TableHead>
            <TableRow>
              <TableCell>Address</TableCell>
              <TableCell>Contact</TableCell>
              <TableCell>Arrival Time</TableCell>
              <TableCell>ETA</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {load.locations &&
              load.locations
                .filter((loc) => loc.type === type)
                .map((row, index) => (
                  <TableRow
                    key={"location_" + type + index}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.address.line1}
                      <br />
                      {row.address.line2 && (
                        <>
                          {row.address.line2}
                          <br />
                        </>
                      )}
                      {row.address.city}, {row.address.state} {row.address.zip}
                      <br />
                      {row.address.country}
                    </TableCell>
                    <TableCell>
                      {row.contact && (
                        <>
                          {row.contact.name}
                          <br />
                          Email: {row.contact.email}
                          <br />
                          Phone: {row.contact.phone}
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      {row.arrival_time
                        ? new Date(row.arrival_time).toLocaleString()
                        : ""}
                    </TableCell>
                    <TableCell>
                      <DateTimePicker
                        id={"datepicker_" + type + index}
                        value={DateTime.fromISO(row.estimated_arrival_time)}
                        onChange={(newValue) => {
                          const loadCopy = { ...load };
                          load.locations.filter((loc) => loc.type === type)[
                            index
                          ].estimated_arrival_time = newValue;
                          setLoad(loadCopy);
                        }}
                        slotProps={{ textField: { size: "small" } }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Modal
      open={load._id !== undefined}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={5000}
          onClose={closeSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={closeSnackbar}
            severity={snackbar.severity}
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
        <Fade in={load._id !== undefined}>
          <Box className="modalPrimary">
            {load._id === undefined || companies.length === 0 || loading ? (
              <Box sx={{ marginY: 2 }}>
                <Loading />
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    float: "right",
                    width: "auto",
                    // marginRight: 2,
                  }}
                >
                  <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={currentTab}
                    variant="scrollable"
                    allowScrollButtonsMobile
                    onChange={(event, newVal) => setCurrentTab(newVal)}
                  >
                    <Tab label="Details" id="details" />
                    <Tab label="Locations" id="Locations" />
                    <Tab label="Claims" id="Claims" />
                    <Tab label="History" id="history" />
                  </Tabs>
                </Box>
                <TabPanel value={currentTab} index={0}>
                  <Grid
                    container
                    sx={{
                      padding: 2,
                      overflowY: "scroll",
                      maxHeight: "calc(100vh - 100px)",
                      borderBottom: "1px solid #eee",
                    }}
                  >
                    <Grid item xs={3} pr={2}>
                      <TextField
                        value={load.qe_id}
                        onChange={updateTitle}
                        label="QE ID"
                        variant="standard"
                        disabled={isReadOnly()}
                        sx={{
                          width: "100%",
                          "& .MuiOutlinedInput-input": { padding: 0 },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "unset ",
                          },
                          "& .MuiOutlinedInput-root": {
                            fontSize: "1.5rem",
                            fontWeight: "700",
                          },
                          marginBottom: 1,
                        }}
                      />
                    </Grid>
                    <Grid item xs={3} pr={2}>
                      <TextField
                        value={load.pro_number}
                        onChange={updateProNumber}
                        label="PRO"
                        variant="standard"
                        fullWidth
                        disabled={isReadOnly()}
                      />
                    </Grid>
                    <Grid item xs={3} pr={2}>
                      <TextField
                        value={load.reference_id}
                        onChange={updateReferenceId}
                        label="Alfred ID"
                        variant="standard"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl fullWidth variant="standard">
                        <InputLabel htmlFor="price" size="small">
                          Cost/Price
                        </InputLabel>
                        <Input
                          value={load.price}
                          onChange={updatePrice}
                          id="price"
                          label="Cost/Price"
                          variant="standard"
                          size="small"
                          fullWidth
                          disabled={isReadOnly()}
                          startAdornment={
                            <InputAdornment position="start">$</InputAdornment>
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        variant="standard"
                        fullWidth
                        sx={{ marginBottom: 1 }}
                      >
                        <InputLabel>Status</InputLabel>
                        <Select value={load.section} onChange={updateStatus} disabled={isReadOnly()}>
                          {props.sections.map((section) => {
                            return (
                              <MenuItem key={section._id} value={section._id}>
                                {section.title}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    {load._id &&
                      companies &&
                      companies.length > 0 &&
                      companyOrder.map((type, index) => {
                        return (
                          <Grid item xs={12} key={"company_" + index}>
                            <FormControl
                              variant="standard"
                              size="small"
                              fullWidth
                              sx={{ marginBottom: 1 }}
                            >
                              {newCompanyTypes[type] && (
                                <TextField
                                  type="text"
                                  autoFocus
                                  // placeholder="Add New"
                                  variant="standard"
                                  label={"Enter New " + typeUpper(type)}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment
                                        position="end"
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                          const newCompanyTypesCopy = {
                                            ...newCompanyTypes,
                                          };
                                          newCompanyTypesCopy[type] = false;
                                          setNewCompanyTypes(
                                            newCompanyTypesCopy
                                          );
                                        }}
                                      >
                                        <CloseOutlined />
                                      </InputAdornment>
                                    ),
                                  }}
                                  onChange={(event) => {
                                    updateCompany(event.target.value, type);
                                  }}
                                />
                              )}
                              {!newCompanyTypes[type] && (
                                <Autocomplete
                                  freeSolo
                                  id={type}
                                  options={[
                                    {
                                      name: "Add New " + typeUpper(type),
                                      new: true,
                                    },
                                    ...companies.filter(
                                      (company) =>
                                        company.types.indexOf(type) >= 0
                                    ),
                                  ]}
                                  defaultValue={companies.find(
                                    (company) => company._id === load[type] || (companies.length === 1 && type === 'customer')
                                  )}
                                  disabled={(companies.length === 1 && type === 'customer') || isReadOnly()}
                                  renderInput={(params) => {
                                    return (
                                      <TextField
                                        {...params}
                                        variant="standard"
                                        label={typeUpper(type)}
                                      />
                                    );
                                  }}
                                  renderOption={(props, option) => {
                                    return (
                                      <li
                                        {...props}
                                        key={type + option.name}
                                        sx={{
                                          marginX: 1,
                                          fontStyle: option.new
                                            ? "italic"
                                            : "inherit",
                                          borderBottom: option.new
                                            ? "1px solid #DDD"
                                            : "none",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {option.name}
                                      </li>
                                    );
                                  }}
                                  getOptionLabel={(option) => option.name}
                                  onChange={(event, newValue) => {
                                    if (newValue) {
                                      if (newValue._id) {
                                        updateCompany(newValue._id, type);
                                      } else if (newValue.new) {
                                        const newCompanyTypesCopy = {
                                          ...newCompanyTypes,
                                        };
                                        newCompanyTypesCopy[type] = true;
                                        setNewCompanyTypes(newCompanyTypesCopy);
                                      }
                                    } else updateCompany(null, type);
                                  }}
                                />
                              )}
                            </FormControl>
                          </Grid>
                        );
                      })}
                    <Grid item xs={6} pr={2}>
                      <TextField
                        value={load.tracking_url}
                        onChange={updateTrackingUrl}
                        label="Tracking URL"
                        variant="standard"
                        fullWidth
                        disabled={isReadOnly()}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={load.tracking_number}
                        label="Tracking Number"
                        variant="standard"
                        disabled
                        fullWidth
                      />
                    </Grid>
                    {!user.isCustomer && <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          type="number"
                          value={alert.number}
                          onChange={(event) => {
                            const alertCopy = { ...alert };
                            alertCopy.number = event.target.value;
                            updateAlert(alertCopy);
                          }}
                          label="Alert Time"
                          variant="standard"
                          sx={{
                            marginRight: "5px",
                            width: "100%",
                            "& .MuiOutlinedInput-input": { padding: 0 },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "unset ",
                            },
                            "& .MuiOutlinedInput-root": {
                              fontSize: "1.5rem",
                              fontWeight: "700",
                            },
                            marginBottom: 1,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl
                          variant="standard"
                          fullWidth
                          sx={{ marginLeft: "5px" }}
                        >
                          <InputLabel>Alert Unit</InputLabel>
                          <Select
                            value={alert.unit}
                            label="Alert Unit"
                            variant="standard"
                            onChange={(event) => {
                              const alertCopy = { ...alert };
                              alertCopy.unit = event.target.value;
                              updateAlert(alertCopy);
                            }}
                          >
                            <MenuItem value={""}>None</MenuItem>
                            <MenuItem value={"minutes"}>Minutes</MenuItem>
                            <MenuItem value={"hours"}>Hours</MenuItem>
                            <MenuItem value={"days"}>Days</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>}
                    <Grid item xs={12} mt={2}>
                      <TextField
                        value={load.notes}
                        label="Notes"
                        fullWidth
                        multiline
                        variant="filled"
                        onChange={updateNotes}
                        disabled={isReadOnly()}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={currentTab} index={1}>
                  <Box my={3}>
                    {renderLocations("pickup", "Pick Up Locations")}
                    <Box mt={2} />
                    {renderLocations("dropoff", "Drop Off Locations")}
                  </Box>
                </TabPanel>
                <TabPanel value={currentTab} index={2}>
                  <List
                    dense={true}
                    sx={{
                      maxHeight: "calc(100vh - 80px)",
                      overflowY: "scroll",
                    }}
                  >
                    <>
                      <ListItem>
                        <Card
                          variant="elevation"
                          sx={{
                            fontSize: ".75rem",
                            width: "100%",
                            padding: 0.5,
                          }}
                        >
                          <Box sx={{ float: "right", color: "#777" }}>
                            Submitted Date:
                          </Box>
                          <Box sx={{ color: "#777" }}>Claim Number:</Box>
                          <Box sx={{ whiteSpace: "pre-line" }}>{}</Box>
                        </Card>
                      </ListItem>
                    </>
                  </List>
                </TabPanel>
                <TabPanel value={currentTab} index={3}>
                  <List
                    dense={true}
                    sx={{
                      maxHeight: "calc(100vh - 80px)",
                      overflowY: "scroll",
                    }}
                  >
                    {load.histories && load.histories.length > 0 ? (
                      <>
                        {[...load.histories].reverse().map((history, index) => {
                          return (
                            <ListItem key={"history_" + index}>
                              <Card
                                variant="elevation"
                                sx={{
                                  fontSize: ".75rem",
                                  width: "100%",
                                  padding: 0.5,
                                }}
                              >
                                <Box sx={{ float: "right", color: "#777" }}>
                                  {new Date(history.timestamp).toLocaleString()}
                                </Box>
                                <Box sx={{ color: "#777" }}>
                                  User: {history.user}
                                </Box>
                                {history.description.map((desc, index) => {
                                  return (
                                    <Box
                                      sx={{ whiteSpace: "pre-line" }}
                                      key={"history_desc_" + index}
                                    >
                                      {desc}
                                    </Box>
                                  );
                                })}
                              </Card>
                            </ListItem>
                          );
                        })}
                      </>
                    ) : (
                      <Alert variant="standard" severity="info">
                        No History
                      </Alert>
                    )}
                  </List>
                </TabPanel>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sx={{mt: 2}}
                  >
                    {canDelete() && <Button
                      size="small"
                      variant="text"
                      color="error"
                      onClick={deleteLoad}
                      sx={{float: 'left'}}
                    >
                      Delete
                    </Button>}
                    {!isReadOnly() && <Button
                      variant="contained"
                      size="small"
                      disableElevation
                      onClick={save}
                      sx={{float: 'right'}}
                    >
                      Save &amp; Close
                    </Button>}
                    {isReadOnly() && <Button
                      variant="contained"
                      size="small"
                      disableElevation
                      onClick={() => onClose(false)}
                      sx={{float: 'right'}}
                    >
                      Close
                    </Button>}
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </Fade>
      </>
    </Modal>
  );
};

export default LoadModal;
