import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItem,
  Modal,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import userApi from "../api/userApi";
import Loading from "../components/common/Loading";
import UserMenu from "../components/common/UserMenu";
import EditIcon from "@mui/icons-material/Edit";
import accessUtils from "../utils/accessUtils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #333",
  borderRadius: "4px",
  boxShadow: 24,
  p: 4,
};

const Users = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState(undefined);
  const [editUser, setEditUser] = useState(undefined);
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: "success",
    message: "",
  });

  useEffect(() => {
    if (!users) getUsers();
  }, []);

  const getUsers = async () => {
    const res = await userApi.getAll();
    console.log(res);
    setUsers(res);
  };

  const closeSnackbar = () => {
    setSnackbar({
      open: false,
      severity: "success",
      message: "",
    });
  };

  const saveEditUser = async () => {
    const res = await userApi.update(editUser);
    await getUsers();
    setEditUser(undefined);
    setSnackbar({
      open: true,
      severity: "success",
      message: "Saved!",
    });
  };

  return (
    <>
      {loading ? (
        <Loading fullHeight />
      ) : (
        <Box>
          <Snackbar
            open={snackbar.open}
            autoHideDuration={2000}
            onClose={closeSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={closeSnackbar}
              severity={snackbar.severity}
              sx={{ width: "100%" }}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
          <Box
            sx={{
              height: "56px",
              padding: "8px",
              borderBottom: "1px solid #AAA",
              textAlign: "center",
              lineHeight: "42px",
            }}
          >
            <Box
              sx={{
                float: "right",
                width: "auto",
              }}
            >
              <UserMenu />
            </Box>
            <Box
              id="title"
              sx={{
                float: "left",
                fontSize: "1.2rem",
                fontWeight: "700",
                paddingLeft: 2,
                color: "primary.main",
              }}
            >
              Load Manager > Users
            </Box>
          </Box>
          <Box sx={{ margin: 2 }}>
            <List dense={true}>
              {users &&
                users.map((user) => {
                  return (
                    <ListItem
                      key={user._id}
                      sx={{ borderBottom: "1px solid #DDD" }}
                    >
                      <Grid container>
                        <Grid item xs={4}>
                          {user.firstname +
                            (user.lastname ? " " + user.lastname : "")}
                        </Grid>
                        <Grid item xs={4}>
                          {accessUtils.isCustomer(user) ? 'Customer' : 'Staff'}
                        </Grid>
                        <Grid item xs={4}>
                          {!accessUtils.isCustomer(user) && <IconButton
                            size="small"
                            sx={{ float: "right" }}
                            onClick={() => {
                              const userCopy = { ...user };
                              if (!userCopy.rate) {
                                userCopy.rate = {
                                  hourly: 0,
                                  holiday_hourly: 0,
                                  overtime_hourly: 0,
                                };
                              }
                              setEditUser(userCopy);
                            }}
                          >
                            <EditIcon fontSize="inherit" />
                          </IconButton>}
                        </Grid>
                      </Grid>
                    </ListItem>
                  );
                })}
            </List>
            {editUser && (
              <Modal
                open={editUser !== undefined}
                onClose={() => setEditUser(undefined)}
              >
                <Box sx={style}>
                  <Typography>Rates</Typography>
                  <Divider sx={{ marginY: 2 }} />
                  <FormControl sx={{ marginY: 1 }}>
                    <TextField
                      value={editUser.rate.hourly}
                      type="number"
                      label="Hourly (USD)"
                      size="small"
                      onChange={(event) => {
                        const userCopy = { ...editUser };
                        userCopy.rate.hourly = parseFloat(event.target.value);
                        setEditUser(userCopy);
                      }}
                    />
                  </FormControl>
                  <FormControl sx={{ marginY: 1 }}>
                    <TextField
                      value={editUser.rate.holiday_hourly}
                      type="number"
                      label="Holiday Hourly (USD)"
                      size="small"
                      onChange={(event) => {
                        const userCopy = { ...editUser };
                        userCopy.rate.holiday_hourly = parseFloat(
                          event.target.value
                        );
                        setEditUser(userCopy);
                      }}
                    />
                  </FormControl>
                  <FormControl sx={{ marginY: 1 }}>
                    <TextField
                      value={editUser.rate.overtime_hourly}
                      type="number"
                      label="Overtime Hourly (USD)"
                      size="small"
                      onChange={(event) => {
                        const userCopy = { ...editUser };
                        userCopy.rate.overtime_hourly = parseFloat(
                          event.target.value
                        );
                        setEditUser(userCopy);
                      }}
                    />
                  </FormControl>
                  <Box sx={{ marginTop: 1 }}>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      sx={{ float: "right" }}
                      onClick={saveEditUser}
                    >
                      Save
                    </Button>
                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      sx={{ float: "right", marginRight: 1 }}
                      onClick={() => setEditUser(undefined)}
                    >
                      Close
                    </Button>
                  </Box>
                </Box>
              </Modal>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default Users;
