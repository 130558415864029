// import "./css/custom-scrollbar.css";
import CssBaseLine from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AppLayout from "./components/layout/AppLayout";
import Home from "./pages/Home";
import Board from "./pages/Board";
import Time from "./pages/Time";
import { LoadProvider } from "./contexts/loads.context";
import Users from "./pages/Users";
import Account from "./pages/Account";
import "./css/App.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { SocketProvider } from "./contexts/socket.context";
import { TaskProvider } from "./contexts/tasks.context";
import { SiteProvider } from "./contexts/site.context";
import { useSelector } from "react-redux";

function App() {
  const user = useSelector((state) => state.user.value);

  const theme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: "#0971f1",
        darker: "#053e85",
      },
      neutral: {
        main: "#64748B",
        contrastText: "#fff",
      },
    },
    typography: {
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        "Segoe UI",
        "Noto Sans",
        "Helvetica",
        "Arial",
        "Roboto",
        "sans-serif",
      ].join(","),
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <SiteProvider>
          <SocketProvider>
            <LoadProvider>
              <TaskProvider>
                <BrowserRouter>
                  <Routes>
                    {user.isCustomer && <Route path="/" element={<AppLayout />}>
                      <Route path="boards/:boardId" element={<Board />} />
                    </Route>}
                    {!user.isCustomer && 
                    <Route path="/" element={<AppLayout />}>
                      <Route index element={<Home />} />
                      <Route path="time" element={<Time />} />
                      <Route path="users" element={<Users />} />
                      <Route path="account" element={<Account />} />
                      <Route path="boards" element={<Home />} />
                      <Route path="boards/:boardId" element={<Board />} />
                    </Route>}
                  </Routes>
                </BrowserRouter>
              </TaskProvider>
            </LoadProvider>
          </SocketProvider>
        </SiteProvider>
      </LocalizationProvider>
      <CssBaseLine />
    </ThemeProvider>
  );
}

export default App;
