import axiosClient from "./axiosClient";

const loadApi = {
  getAll: () => axiosClient.get("loads"),
  create: (boardId, params) =>
    axiosClient.post(`boards/${boardId}/loads`, params),
  updatePosition: (boardId, params) =>
    axiosClient.put(`boards/${boardId}/loads/update-position`, params),
  delete: (boardId, loadId) =>
    axiosClient.delete(`boards/${boardId}/loads/${loadId}`),
  update: (boardId, loadId, params) =>
    axiosClient.put(`boards/${boardId}/loads/${loadId}`, params),
  lock: (boardId, loadId, params) =>
    axiosClient.put(`boards/${boardId}/loads/lock/${loadId}`, params),
};

export default loadApi;
